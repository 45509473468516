<style lang="scss">
@import "~@/assets/css/var";

.popup-class {
    font-size: 12px;
    max-height: 390px;
    overflow-y: auto;
    background: linear-gradient(180deg, #D1E0FC 0%, rgba(255, 255, 255, 1) 32%);

    &::-webkit-scrollbar {
        display: none;
    }

    &__i {
        color: $main;
    }
}

.bidding-info-prov {
    .my-table {
        height: 100%;

        .icon {
            color: $main;
            cursor: pointer;
        }

        .up {
            color: $red;
        }

        .down {
            color: $green;
        }
    }
}

.bidding-info-prov_trend {
    display: flex;

    .bid-compare {
        width: 70%;
    }

    .ts-table-message {
        width: 30%;

        .el-table {
            box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);

            .custom-table-head {
                background-color: #E3EBFE;
                color: #424242;
            }

            .drugs-origin {
                cursor: pointer;
                color: $main;
            }
        }
    }
}
</style>

<template>
    <div class="common-table bidding-info-prov" v-if="loaded">
        <div class="common-table__title flex">
            <!-- 药品招投标 -->
            省份药品价格
            <el-popover placement="right-start" width="400" trigger="hover" popper-class="popup-class">
                <i slot="reference" class="el-icon-info popup-class__i" style="color: #45d75f;"></i>
                <i></i>
                <p class="through-page-tips" style="white-space: pre-wrap;">
                    1.数据计算范围<br>
                    1.1连续5年数据（2018-2023）；<br>
                    1.2价格为空，不列入计算；<br>
                    1.3执行标的：激活中,禁用,不执行,终止,待执行,未中选；不纳入计算范围；<br>
                    1.4价格属性：不符合申报、不予挂网、取消挂网，不纳入计算范围；<br>
                    1.5计算全国价格维度：药品+企业+规格+转化比；<br>
                    1.6计算省份价格维度：省份+药品+企业+规格+转化比；<br>
                    <br>
                    2.近期趋势计算原则【6项定义】<br>
                    2.1近期上涨：最近一次相较于上一次上涨；<br>
                    2.2近期下跌：最近一次相较于上一次下跌；<br>
                    2.3近期持平：最近一次相较于上一次持平；<br>
                    2.4近期暴涨：在近三次价格中（关系为且）<br>
                    2.4.1最新中标价大于均价；<br>
                    2.4.2最新中标价大于上次中标价*1.2；<br>
                    2.4.3最新中标价大于等于最高价；<br>
                    2.5近期暴跌：在近三次价格中（关系为且）<br>
                    2.5.1最新中标价小于均价；<br>
                    2.5.2最新中标价小于上次中标价*0.8；<br>
                    2.5.3最新中标价小于等于最低价；<br>
                    2.6空：仅1条数据值，默认为空；<br>
                    <br>
                    3.近5年趋势【9项定义】<br>
                    3.1持续上涨：上涨的幅度大于下跌的幅度，且每次数据都比上一次大；<br>
                    3.2持续下跌：上涨的幅度小于下跌的幅度，且每次数据都比上一次小；<br>
                    3.3持续持平：上涨的幅度等于下跌的幅度，且每次数据都等于上一次；<br>
                    3.4整体持平：上涨的幅度等于下跌的幅度，且不满足持续持平；<br>
                    3.5稳定上涨：上涨的幅度大于下跌的幅度，且整体的涨跌次数中，上涨的频率>60%,（总次数需去除不变的次数）；且最大跌幅小于最大涨幅的50%；<br>
                    3.6稳定下跌：上涨的幅度小于下跌的幅度；且整体的涨跌次数中，下跌的频率>60%,（总次数需去除不变的次数）；且最大涨幅小于最大跌幅的50%；<br>
                    3.7震荡上涨：上涨的幅度大于下跌的幅度；且不满足持续上涨和稳定上涨；<br>
                    3.8震荡下跌：上涨的幅度小于下跌的幅度；且不满足持续下跌和稳定下跌；<br>
                    3.9空：仅1条数据值，默认为空；<br>
                </p>
            </el-popover>
            <!-- <ts-table-operate :cols="cols" @handleFilter="handleFilter" table-name="aim_base_drugs_province_winning"
                @leadingOut="leadingOut" @searchShowLeft="searchShowLeft">
            </ts-table-operate> -->
            <div class="flex" style="display: inline; margin-left: 20px;"
                v-if="outSearchCols && outSearchCols.length > 0">
                <div v-for="col in outSearchCols" :key="col.id" style="margin-right: 5px;">
                    <el-input v-if="col.listQueryModel === $variable.searchType.LIKE" style="width: 220px"
                        v-model="outSearchForm[col.fieldName]" :placeholder="'请输入' + col.name"></el-input>
                    <el-select v-else-if="col.listQueryModel === $variable.searchType.SELECT"
                        v-model="outSearchForm[col.fieldName]" filterable remote :placeholder="'请选择' + col.name"
                        clearable multiple collapse-tags @focus="selectRemote(col, undefined, 'focus')" reserve-keyword
                        style="width: 240px" :remote-method="(argument) => { selectRemote(col, argument) }"
                        :loading="col.loading">
                        <el-option v-for="(child, key) in col.list" :key="key"
                            :value="col.useKey ? child.key : child.value" :label="child.value"></el-option>
                    </el-select>
                </div>
                <div style="margin-left: 5px;"><el-button type="primary" @click="outSearch">筛选</el-button></div>
            </div>
        </div>
        <div class="ts-table flex flex-item flex-column">
            <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
                :name="config.key" @handleSort="sort" :error="pageParam.error" :error-code="error"
                @handleRowDbClickChange="handleRowDbClickChange" full @handleSizeChange="handleSizeChange" hide-detail
                @handleCurrentChange="handleCurrentChange">
                <!-- <template #searchLeft>
                    <ts-search-left v-if="isSearchLeft" :searchCols="searchCols"
                        table-name="aim_base_drugs_province_winning" :cols="cols" @searchClose="searchClose"
                        @handleFilter="handleFilter"></ts-search-left>
                </template> -->
                <template v-slot:slot_price="{ props }">
                    <div @click="showCanvas(props.row)" class="icon">
                        <i class="iconfont icon-a-rongqi1"></i>
                    </div>
                </template>
                <template v-slot:slot_packagingPrice="{ props }">
                    <span v-if="props.row.conversionRatio && props.row.minUnitPrice">
                        {{ (props.row.conversionRatio * props.row.minUnitPrice).toFixed(2).replace(/(\.0*|0)$/, '') }}
                    </span>
                </template>
                <template v-slot:slot_upsDownsRate="{ props }">
                    <div :class="[props.row.upsDownsRate > 0 ? 'up' : 'down']">
                        {{ props.row.upsDownsRate | filterRate }}
                    </div>
                </template>
                <template v-slot:slot_priceState="{ props }">
                    <div :class="[props.row.upsDownsRate > 0 ? 'up' : props.row.upsDownsRate < 0 ? 'down' : '']">
                        {{ props.row.priceState }}
                    </div>
                </template>
                <template v-slot:slot_fivePriceState="{ props }">
                    <div v-if="props.row.fivePriceState === '持平'">持平</div>
                    <div :class="['持续上升', '稳定上升', '震荡上升'].includes(props.row.fivePriceState) ? 'up' : 'down'">
                        {{ props.row.fivePriceState }}
                    </div>
                </template>
            </ts-table>
        </div>

        <ts-dialog :show.sync="bidDialog.show" title="中标情况" width="1200px" close-on-click-modal hide-footer>
            <div class="bidding-info-prov_trend" v-loading="bidDialog.loading">
                <my-bid-compare v-if="bidDialog.show" :row="bidDialog.source" type="bidProvince"></my-bid-compare>
                <div class="ts-table-message">
                    <el-table stripe height="500px" :data="bidDialog.list" header-cell-class-name="custom-table-head">
                        <el-table-column prop="winningTime" align="center" width="100" label="日期"></el-table-column>
                        <el-table-column label="文件名">
                            <template slot-scope="scope">
                                <div class="drugs-origin" @click="goPage(scope.row)">
                                    {{ scope.row.releaseDocuments }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </ts-dialog>
    </div>
</template>

<script>
import MyBidCompare from '@/components/MyBidCompare.vue'
import myMixin from '@/util/mixin'
export default {
    components: { MyBidCompare },
    props: ['mainKey'],
    mixins: [myMixin],
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'BiddingInfoProvince',
            pageName: '省份药品价格',

            currentParam: {
                page: 'mainf/aimBaseDrugsProvinceWinning/queryAimBaseDrugsProvinceWinningPage',
                export: '',
            },
            outSearchCols: [],
            key: 'aim_base_drugs_province_winning',
            cols: this.$help.generalCols([{
                fieldName: 'priceState',
                name: '近期\\n趋势',
                width: 50,
                listQueryModel: 0,
                listView: '是',
                listClass: 'tc',
                listOrderView: '是',
                // listStipulate: 1
            },
            {
                fieldName: 'fivePriceState',
                name: '近5年\\n期趋势',
                width: 50,
                listQueryModel: 0,
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
                listOrderView: '是',
            },
            {
                fieldName: 'upsDownsRate',
                name: '涨跌幅',
                width: 60,
                listQueryModel: 0,
                listView: '是',
                listOrderView: '是',
                // listStipulate: 1
            },
            {
                fieldName: 'winningProvince',
                name: '省份',
                width: 70,
                listQueryView: '是',
                listOrderView: '是',
                listQueryModel: 1,
                listView: '是',
                listOutQueryView: '是',
                // listStipulate: 1
            },
            {
                fieldName: 'comName',
                name: '药品通用名',
                width: 120,
                listQueryView: '是',
                listQueryModel: 1,
                listView: '是',
                listOrderView: '是',
                listOutQueryView: '是',
            },
            {
                fieldName: 'compName',
                name: '企业',
                width: 160,
                listQueryView: '是',
                listQueryModel: 1,
                listOrderView: '是',
                listOutQueryView: '是',
                // listStipulate: 1
            },
            {
                fieldName: 'unifySpec',
                name: '规格',
                width: 80,
                listQueryModel: 0,
                listView: '是',
                listOrderView: '是',
                // listStipulate: 1
            },
            {
                fieldName: 'conversionRatio',
                name: '转换比',
                width: 70,
                listQueryModel: 0,
                listOrderView: '是',
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
            },
            {
                fieldName: 'minUnitPrice',
                name: '最新制\\n剂价',
                width: 100,
                listQueryModel: 0,
                listOrderView: '是',
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
            },
            {
                fieldName: 'packagingPrice',
                name: '包装价',
                width: 70,
                listQueryModel: 0,
                listOrderView: '是',
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
            },
            {
                fieldName: 'winningTime',
                name: '最新中\\n标时间',
                width: 100,
                listQueryModel: 0,
                listOrderView: '是',
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
            },
            {
                fieldName: 'minPrice',
                name: '省份最\\n低价格',
                width: 80,
                listQueryModel: 0,
                listOrderView: '是',
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
            },
            {
                fieldName: 'maxPrice',
                name: '省份最\\n高价格',
                width: 80,
                listQueryModel: 0,
                listOrderView: '是',
                listClass: 'tc',
                listView: '是'
                // listStipulate: 1
            },
            {
                fieldName: 'avgPrice',
                name: '省份平\\n均价格',
                listOrderView: '是',
                width: 80,
                listQueryModel: 0,
                listClass: 'tc',
                listView: '是',
                // listStipulate: 1
            },
            {
                fieldName: 'propertyPrice',
                name: '价格\\n属性',
                width: 80,
                listQueryModel: 0,
                listView: '是',
                listOrderView: '是',
                // listStipulate: 1
            },
            {
                fieldName: 'price',
                name: '趋势图',
                width: 60,
                listQueryView: '否',
                listQueryModel: 1,
                listClass: 'tc',
            }]),
            bidDialog: {
                show: false,
                source: {},
                txt: '',
                list: [],
                loading: false,
            },
        }
    },
    filters: {
        filterRate(val) {
            let str = '';
            if (val) str = val + '%';
            return str;
        }
    },
    watch: {
        'outSearchForm.comName': {
            handler(val) {
                this.newOutSearchCols('compName', val && val.length <= 1)
            },
        },
        'outSearchForm.keyName': {
            handler(val) {
                this.newOutSearchCols('comName', val && val.length <= 1)
            },
        },
    },
    created() {

        if (this.cols) {
            const cols = this.$help.deepClone(this.cols)
            this.outSearchCols = cols.filter(item => {
                return item.listQueryView === '是' && item.listOutQueryView === '是'
            })
            this.outSearchCols.splice(1, 0, { fieldName: 'keyName', name: '关键字名称', listQueryModel: 1, })
        }
        this.$help.socket.send(this)
    },
    methods: {
        showCanvas(row) {
            this.bidDialog.show = true
            this.bidDialog.source = row
            this.getNewsList(row)
        },
        getNewsList(row) {
            this.bidDialog.loading = true
            this.$api.get('mainf/aimBaseDrugsProvinceWinning/queryProvinceInfo',
                { aimBaseDrugsProvinceWinningId: row.id }).then(res => {
                    let list = []
                    if (res.success) {
                        list = res.data
                    }
                    this.bidDialog.list = list
                }).finally(() => {
                    this.bidDialog.loading = false
                })
        },
        //招投标信息
        goPage(row) {
            let query = {
                releaseDocuments: row.releaseDocuments,
            }
            query.isParam = true
            query.str = 'BiddingInfoCountry-BiddingInfoProvince'
            let arr = [
                { name: 'compCode', op: 'eq', value: this.bidDialog.source.compCode, },
                { name: 'comCode', op: 'eq', value: this.bidDialog.source.comCode, },
                // { name: 'unifyDoseCode', op: 'eq', value: this.bidDialog.source.unifyDoseCode, },
                { name: 'unifySpecCode', op: 'eq', value: this.bidDialog.source.unifySpecCode, },
                { name: 'conversionRatio', op: 'eq', value: this.bidDialog.source.conversionRatio, },
                // { name: 'price', op: 'isNotNull', value: null, },
                { name: 'targetPerformanceTypeName', op: 'notIn', value: [ '不执行', '终止', '待执行', '未中选'], },
                { name: 'releaseDocuments', op: 'eq', value: row.releaseDocuments },
                // { name: "winningTime", op: "eq", value: row.winningTime },
            ]
            arr = JSON.stringify(arr)
            this.$help.store.set('BiddingInfoCountry-BiddingInfoProvince', arr)
            const { href } = this.$router.resolve({
                path: '/store/mainf_drug_winning_informations',
                query,
            });
            window.open(href, "_blank");
        },
        newSelectRemote(row) {
            let obj = undefined
            let outSearchReal = []
            this.outSearchCols.forEach(col => {
                const val = this.outSearchForm[col.fieldName]
                if (val && val.length > 0) {
                    if (col.listQueryModel === this.$variable.searchType.SELECT) {
                        outSearchReal.push({
                            name: col.fieldName,
                            op: 'in',
                            value: val,
                        })
                    }
                }
            })
            let compObj = outSearchReal.find(item => item.name === 'comName')   //控制企业
            let comObj = outSearchReal.find(item => item.name === 'keyName')    //控制药品
            if (row.fieldName === 'comName') {
                this.newOutSearchCols('compName', compObj && compObj.value.length === 1)
            } else if (row.fieldName === 'keyName') {
                this.newOutSearchCols('comName', comObj && comObj.value.length === 1)
            }
            if (compObj && compObj.value.length === 1 && row.fieldName === 'compName') {
                obj = this.$help.deepClone(compObj)
            } else if (comObj && comObj.value.length === 1 && row.fieldName === 'comName') {
                obj = this.$help.deepClone(comObj)
            }
            return obj
        },
        newOutSearchCols(key, obj) {
            this.outSearchCols = this.outSearchCols.map(col => {
                if (col.fieldName === key && obj) {
                    col.list = []
                }
                return col
            })
        },
    }
}
</script>